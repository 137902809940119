<template>
    <div class="Normal">
        <div class="inner">
            <div class="sub_visual sub1bg">
                <div class="in"></div>
            </div>
        </div>
        <div class="sub_content">
            <div class="main">
                <input type="radio" id="tab-1" @click="changeType('일반공급')" name="show" checked/>
                <input type="radio" id="tab-2" @click="changeType('특별공급')" name="show" />
                <input type="radio" id="tab-3" @click="changeType('당첨자')" name="show" />
                <div class="tab">
                    <label for="tab-1">일반공급</label>
                    <label for="tab-2">특별공급</label>
                    <label for="tab-3">당첨자조회</label>
                </div>
                <div class="content">
                    <!-- 일반공급 -->
                    <div class="content-dis">
                        <div class="titlebox" name="normal">
                            <div class="title">
                                <!-- <p class="sub-title">일반공급</p> -->
                                <h2>일반공급 지원자격</h2>
                            </div>
                            <div class="textbox">
                                <div class="textbox_txt">
                                    <p class="text1" style="margin-bottom: 0px;">
                                        <span>청년 계층 <br> 신청자격</span>
                                    </p>
                                    <p class="text2" style="margin-bottom: 50px; margin-top:0px; height: 155px">
                                        <span>
                                            ① 만 19세 이상 만 39세 이하인 자 <br>
                                            ② 미혼 <br>
                                            ③ 무주택자 <br>
                                            ④ 자동차 무소유, 미운행자 <br>
                                        </span>
                                        <span class="small">(다만, 차량가액은 자동차 가액 3,557만원 이하의 장애인 사용(본인사용), 생업용, 125cc이하의 생업용 이륜차는 가능)</span>
                                    </p>
                                    <p class="text1">
                                        <span>신혼부부(혼인 후 2,555일) 및 <br> 예비신혼부부 계층<br> 신청자격</span>
                                    </p>
                                    <p class="text2">
                                        <span>
                                        ① 만 19세 이상 만 39세 이하인 자 <br>
                                        ② 신혼부부는 혼인중인자, 예비신혼부부는 해당 주택의 입주 전까지 혼인사실을 증명할 수 있는자 <br>
                                        ③ 무주택세대구성원 <sapn class="small" style=" margin-left: 0px;"> (예비신혼부부의 경우 무주택자) </sapn><br>
                                        ④ 자동차 무소유, 미운행자 <br>
                                        </span>
                                        <span class="small">(다만, 차량가액은 자동차 가액 3,557만원 이하의 장애인 사용(본인사용), 생업용, 125cc이하의 생업용 이륜차는 가능)</span>
                                    </p>
                                    <p class="text6">
                                        *소득 및 자산 기준 없음. <br>
                                        *준공 전 최초 임대주택 신청자는 홈페이지에 게시된 입주자모집공고문을 반드시 숙지 후 자격조건 및 제출서류 등을 확인 후 계약신청하시길 바랍니다.
                                    </p>
                                </div>
                            </div>
                            <div class="application">
                                <div class="application_title">일반공급 계약(청약)신청서 작성</div>
                                <div class="contactSection">
                                    <div class="contact">
                                        <table>
                                            <tbody>
                                                <tr style="border-top: 2px solid #707070;">
                                                    <td class="content_title">성명</td>
                                                    <td class="box">
                                                        <input type="text" size="25"  placeholder="" name="user_name" id="user_Name" v-model="userData.user_name" required />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="content_title">전화번호</td>
                                                    <td class="box">
                                                        <input type="tel" max="11" size=30  placeholder="" id="user_Number" v-model="userData.user_number" /><span class="description"> '-'을 제외한 휴대폰번호를 입력해 주세요</span>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="content_title">생년월일</td>
                                                    <td class="box">
                                                        <input type="text" size="25" class="non-kr" id="user_Birth" placeholder=""  aria-invalid="false" v-model="userData.user_birth" /><span class="description"> 예) 2019-12-31</span>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="content_title">거주유형</td>
                                                    <td class="box">
                                                        <label><input type="radio" class="radio" placeholder="" name="residence_type" id="residence_type" v-model="userData.residence_type" value="청년"> 청년</label>
                                                        <label><input type="radio" class="radio" placeholder="" name="residence_type" id="residence_type" v-model="userData.residence_type" value="신혼부부"> 신혼부부</label>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="content_title">세대타입</td>
                                                    <td class="box">
                                                        <label><input type="radio" class="radio" placeholder="" name="house_type" id="house_type" v-model="userData.house_type" value="17A"> 17A</label>
                                                        <label><input type="radio" class="radio" placeholder="" name="house_type" id="house_type" v-model="userData.house_type" value="17B"> 17B</label>
                                                        <label><input type="radio" class="radio" placeholder="" name="house_type" id="house_type" v-model="userData.house_type" value="33A"> 33A</label>
                                                        <label><input type="radio" class="radio" placeholder="" name="house_type" id="house_type" v-model="userData.house_type" value="33B"> 33B</label>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="content_title">입주일</td>
                                                    <td class="box">
                                                        <label><input type="radio" class="radio" placeholder="" name="move_date" id="move_Date" v-model="userData.move_date" value="2022-05-26"> 5월 26일</label>
                                                        <label><input type="radio" class="radio" placeholder="" name="move_date" id="move_Date" v-model="userData.move_date" value="2022-05-27"> 5월 27일</label>
                                                        <label><input type="radio" class="radio" placeholder="" name="move_date" id="move_Date" v-model="userData.move_date" value="2022-05-28"> 5월 28일</label>
                                                        <label><input type="radio" class="radio" placeholder="" name="move_date" id="move_Date" v-model="userData.move_date" value="2022-05-29"> 5월 29일</label>
                                                        <label><input type="radio" class="radio" placeholder="" name="move_date" id="move_Date" v-model="userData.move_date" value="2022-05-30"> 5월 30일</label>
                                                        <label><input type="radio" class="radio" placeholder="" name="move_date" id="move_Date" v-model="userData.move_date" value="2022-05-31"> 5월 31일</label><br>
                                                        <span>(2022. 5.26 / 27 / 28 / 29 / 30 / 31 중 택1)</span>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="content_title">선호층수</td>
                                                    <td class="box">
                                                        <label><input type="radio" class="radio" placeholder="" name="prefer_floor" id="prefer_floor" v-model="userData.prefer_floor" value="저층"> 저층부</label>
                                                        <label><input type="radio" class="radio" placeholder="" name="prefer_floor" id="prefer_floor" v-model="userData.prefer_floor" value="중층" > 중층부</label>
                                                        <label><input type="radio" class="radio" placeholder="" name="prefer_floor" id="prefer_floor" v-model="userData.prefer_floor" value="고층"> 고층부</label><br>
                                                        <span>(저층부 : 3~6, 중층부 : 7~12, 고층부: 13~19 중 택1)</span>
                                                    </td>	
                                                </tr>
                                            </tbody>
                                        </table>
                                        <p class="txt">* 선호층수에 최대한 배정을 해드리고자하나 본인이 선호하는 층수에 반드시 배정되지 않을 수 있고, 당첨확률과는 전혀 무관함을 미리 알려드립니다.</p>
                                        <p class="txt" style="color: red; margin-top: -10px; margin-bottom: 50px;">* 세대타입당 한번만 신청이 가능하니 신중하게 신청해주세요. (신청 후 수정 불가)</p>
                                        <button type="button" @click="send(userData)"><p>신청하기</p></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- 특별공급 -->
                    <div class="content-dis">
                        <div class="titlebox">
                            <div class="title">
                                <!-- <p class="sub-title">특별공급</p> -->
                                <h2>특별공급 지원자격</h2>
                            </div>
                            <div class="textbox" style="height: 410px;">
                                <div class="textbox_txt">
                                    <p class="text1" style="height: 333px; margin-bottom: 30px;">
                                        <span>청년 (예비)신혼부부 <br> 신청자격</span>
                                    </p>
                                    <p class="text2" style="margin-bottom: 35px;">
                                        <span>
                                            ① 만 19세 이상 만 39세 이하인 자 <br>
                                            ② 청년은 미혼, 신혼부부는 혼인중인자, 예비신혼부부는 해당 주택의 입주 전까지 혼인사실을 증명할 수 있는자 <br>
                                            ③ 청년 및 예비신혼부부는 무주택자, 신혼부부는 무주택세대구성원 <br>
                                            ④ 자동차 무소유, 미운행자 <br>
                                            <span class="small" style="margin-top: -6px;">(다만, 차량가액은 자동차 가액 3,557만원 이하의 장애인 사용(본인사용), 생업용, 125cc이하의 생업용 이륜차는 가능)</span>
                                            ⑤ 세대원별 월 평균소득 기준에 의함(모집공고문 참조 필수)
                                            <span class="small" style="margin-top: 0px;  line-height:1.1;">
                                                - 신청인이 소득이 있는 경우는 “해당세대” 월평균 소득 <br>
                                                <!-- - 신청자가 소득이 있고 단독세대주 또는 세대원인 경우 신청자 본인만의 소득, 신청자가 세대원이 있는 세대주인 경우<br>
                                                &nbsp;&nbsp;무주택세대구성원 모두의 소득<br> -->
                                                - 신청자가 단독세대주인 경우 신청자 본인만의 소득, 세대원 또는 세대원이 있는 세대주인 경우 해당세대 모두의 소득<br>
                                                - 신청자가 소득이 없는 경우는 납세사실확인증명서(소득없음 증빙)후 신청자와 “부모”의 월 평균 소득합계<br>
                                            </span>
                                            ⑥ 본인 자산 가액 2억8,800만원 이하(신혼부부는 2억9,200만원 이하)
                                        </span>
                                    </p>
                                    <p class="text6" style="margin-top:10px;">
                                        *특별공급과 일반공급의 세대 내 마감을 비롯한 옵션 등 시공품질 및 제공옵션은 모두 동일합니다.<br>
                                        *준공 전 최초 임대주택 신청자는 홈페이지에 게시된 입주자모집공고문을 반드시 숙지 후 자격조건 및 제출서류 등을 확인 후 계약신청하시길 바랍니다.
                                    </p>
                                </div>
                            </div>
                            <div class="application">
                                <div class="application_title">특별공급 계약(청약)신청서 작성</div>
                                <div class="contactSection">
                                    <div class="contact">
                                        <table>
                                            <tbody>
                                                <tr style="border-top: 2px solid #707070;">
                                                    <td class="content_title">성명</td>
                                                    <td class="box">
                                                        <input type="text" size="25" placeholder="" name="user_name" id="user_Name" v-model="userData2.user_name" required />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="content_title">전화번호</td>
                                                    <td class="box">
                                                        <input type="tel" max="11" size=30 placeholder="" id="user_Number" v-model="userData2.user_number" /><span class="description"> '-'을 제외한 휴대폰번호를 입력해 주세요</span>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="content_title">생년월일</td>
                                                    <td class="box">
                                                        <input type="text" size="25" class="non-kr" id="user_Birth" placeholder=""  aria-invalid="false" v-model="userData2.user_birth" /><span class="description"> 예) 2019-12-31</span>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="content_title"> 주소 <p style="margin-bottom:0; font: normal normal normal 16px/25px Noto Sans KR; letter-spacing: 0.24px; color: #000000; line-height: 10px !important;">(주민등록표 주소)</p> </td>
                                                    <td class="box">
                                                        <input type="text"  size="50" placeholder="" name="address" id="Address"  v-model="userData2.address" > 
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="content_title">월평균소득</td>
                                                    <td class="box">
                                                        <input type="int" size=25 placeholder="" name="income" id="Income"  v-model="userData2.income" ><span class="description"> 원</span>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="content_title">자산</td>
                                                    <td class="box">
                                                        <input type="int" size=25 placeholder="" name="property" id="Property"  v-model="userData2.property" ><span class="description"> 원</span>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="content_title">거주유형</td>
                                                    <td class="box">
                                                        <label><input type="radio" class="radio" placeholder="" name="residence_type" id="residence_type" v-model="userData2.residence_type" value="청년"> 청년</label>
                                                        <label><input type="radio" class="radio" placeholder="" name="residence_type" id="residence_type" v-model="userData2.residence_type" value="신혼부부"> 신혼부부</label>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="content_title">세대타입</td>
                                                    <td class="box">
                                                        <label><input type="radio" class="radio" placeholder="" name="house_type" id="house_type" v-model="userData2.house_type" value="17AS"> 17A</label>
                                                        <!-- <label><input type="radio" class="radio" placeholder="" name="house_type" id="house_type" v-model="userData2.house_type" value="17B"> 17B</label> -->
                                                        <label><input type="radio" class="radio" placeholder="" name="house_type" id="house_type" v-model="userData2.house_type" value="33AS"> 33A</label>
                                                        <label><input type="radio" class="radio" placeholder="" name="house_type" id="house_type" v-model="userData2.house_type" value="33BS"> 33B</label>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="content_title">입주일</td>
                                                    <td class="box">
                                                        <label><input type="radio" class="radio" placeholder="" name="move_date" id="move_Date" v-model="userData2.move_date" value="2022-05-26"> 5월 26일</label>
                                                        <label><input type="radio" class="radio" placeholder="" name="move_date" id="move_Date" v-model="userData2.move_date" value="2022-05-27"> 5월 27일</label>
                                                        <label><input type="radio" class="radio" placeholder="" name="move_date" id="move_Date" v-model="userData2.move_date" value="2022-05-28"> 5월 28일</label>
                                                        <label><input type="radio" class="radio" placeholder="" name="move_date" id="move_Date" v-model="userData2.move_date" value="2022-05-29"> 5월 29일</label>
                                                        <label><input type="radio" class="radio" placeholder="" name="move_date" id="move_Date" v-model="userData2.move_date" value="2022-05-30"> 5월 30일</label>
                                                        <label><input type="radio" class="radio" placeholder="" name="move_date" id="move_Date" v-model="userData2.move_date" value="2022-05-31"> 5월 31일</label><br>
                                                        <span>(2022. 5.26 / 27 / 28 / 29 / 30 / 31 중 택1)</span>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="content_title">선호층수</td>
                                                    <td class="box">
                                                        <label><input type="radio" class="radio" placeholder="" name="prefer_floor" id="prefer_floor" v-model="userData2.prefer_floor" value="저층"> 저층부</label>
                                                        <label><input type="radio" class="radio" placeholder="" name="prefer_floor" id="prefer_floor" v-model="userData2.prefer_floor" value="중층" > 중층부</label>
                                                        <label><input type="radio" class="radio" placeholder="" name="prefer_floor" id="prefer_floor" v-model="userData2.prefer_floor" value="고층"> 고층부</label><br>
                                                        <span>(저층부 : 3~6, 중층부 : 7~12, 고층부: 13~19 중 택1)</span>
                                                    </td>	
                                                </tr>
                                            </tbody>
                                        </table>
                                        <p class="txt">* 선호층수에 최대한 배정을 해드리고자하나 본인이 선호하는 층수에 반드시 배정되지 않을 수 있고, 당첨확률과는 전혀 무관함을 미리 알려드립니다.</p>
                                        <p class="txt" style="color: red; margin-top: -10px; margin-bottom: 50px;">* 세대타입당 한번만 신청이 가능하니 신중하게 신청해주세요. (신청 후 수정 불가)</p>
                                        <button type="button" @click="send2(userData2)"><p>신청하기</p></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- 당첨자조회 -->
                    <div class="content-dis">
                        <div class="titlebox">
                            <div class="title">
                                <h2>당첨자 조회</h2>
                            </div>
                            <div class="winner">
                                <table>
                                    <tbody>
                                        <tr style="border-top: 2px solid #707070;">
                                            <td class="content_title">성명</td>
                                            <td>
                                                <input type="text" placeholder="" name="user_name" id="user_Name" v-model="userData3.user_name" required />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="content_title">전화번호</td>
                                            <td>
                                                <input type="tel" size=30 max="11" v-model="userData3.user_number" placeholder="" id="user_Number" /><span class="description"> '-'을 제외한 휴대폰번호를 입력해 주세요</span>
                                            </td>
                                        </tr>
                                        <tr style="border-bottom: 2px solid #707070;">
                                            <td class="content_title">생년월일</td>
                                            <td> 
                                                <input type="text" class="non-kr" id="user_Birth" placeholder=""  aria-invalid="false" v-model="userData3.user_birth"/><span class="description"> 예) 2019-12-31</span>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <button type="button" @click="WinnerBtn(userData3)"><p>조회하기</p></button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Modal v-if="ModalOpen" @close="ModalOpen=false">
            <h3 slot="header">
                알림!
                <i class="fas fa-times closeModalBtn" @click="ModalOpen = false"></i>
            </h3>
            <div slot="body">
                신청기간이 아닙니다.<br />
                신청 시작 시간: 2022.03.18(금) 00:00:00<br />
                신청 마감 시간: 2022.03.23(수) 23:59:00
            </div>
        </Modal>
        <Modal v-if="DangcheomModal" @close="DangcheomModal=false">
            <h3 slot="header">
                알림!
                <i class="fas fa-times closeModalBtn" @click="DangcheomModal = false"></i>
            </h3>
            <div slot="body">
                조회기간이 아닙니다.<br />
                조회 가능 기간: 2022.03.25(금) 14:00:00 ~
            </div>
        </Modal>
        <Modal v-if="isResultModal" @close="isResultModal=false">
            <h3 slot="header">
                당첨 결과 안내
                <i class="fas fa-times closeModalBtn" @click="isResultModal = false"></i>
            </h3>
            <div slot="body">
                <p v-if="isResult"> 합격을 축하드립니다. <br>
                    <span style="font: normal normal normal 17.5px/50px Noto Sans KR; color:black;"> 본인 해당 동호수는 홈페이지 게시판을 통해 확인바랍니다. </span>
                </p>
                <p v-if="!isResult"> 예비당첨되셨습니다. <br> 
                    <span style="font: normal normal normal 17.5px/50px Noto Sans KR; color:black;"> 당첨자 중 계약포기자 또는 부적격자 발생시 우선적으로 연락드리도록 하겠습니다. </span>
                </p>
            </div>
        </Modal>
    </div>    
</template>

<script>
import axios from 'axios';
import Modal from '../components/Modal.vue';

export default {
    components:{
        Modal
    },
    props: {
        contract_type: {
            type: String,
            default: "",
        },
        name: {
            type: String,
            default: "",
        },
        // popupVal: {}
    }, 
    watch: {
        contract_type : {
            handler(type) {
                this.changeView(type)
            },
            deep: true
        }
    },
    created() {
    },
    mounted() {
        this.changeView(this.contract_type);
    },
	data() {
		return {
            // 일반공급
			userData:{
				user_name: '', // 이름
				user_birth: '', // 생년월일
				user_number: '', // 핸드폰 번호
				residence_type: '', // 거주유형
				house_type: '', // 세대유형
				prefer_floor: '', // 선호층수
				move_date: '', // 입주일
			},
            // 특별공급
			userData2:{
				user_name: '', // 이름
				user_birth: '', // 생년월일
				user_number: '', // 핸드폰 번호
				residence_type: '', // 거주유형
				house_type: '', // 세대유형
				prefer_floor: '', // 선호층수
				move_date: '', // 입주일
				income: '', // 월소득
				property: '', // 재산
				address: '', // 주소
			},
            // 당첨자조회
            userData3: { 
				user_name: '', // 이름
				user_birth: '', // 생년월일
				user_number: '', // 핸드폰 번호
            },
            ModalOpen: false,
            DangcheomModal:false,
            isResultModal: false,
            isResult: false,
            // open: false
		}
	},
	methods: {
        changeView(type) {
            if(type == '일반공급') { 
                document.getElementById('tab-1').checked = true;
            } else if(type == '특별공급') {
                document.getElementById('tab-2').checked = true;
            } else if(type == '당첨자') {
                document.getElementById('tab-3').checked = true;
            }
        },
        // 일반공급
	send(list) {
			// 유효성검사 
			var name = /^[가-힣]{2,4}|[a-zA-Z]{2,10}\s[a-zA-Z]{2,10}$/; //이름
			var birth = /^(19[0-9][0-9]|20\d{2})-(0[0-9]|1[0-2])-(0[1-9]|[1-2][0-9]|3[0-1])$/ // 생년월일
			var phone = /^01([0|1|6|7|8|9])-?([0-9]{3,4})-?([0-9]{4})$/; //핸드폰 번호 
            
			console.log(list)			

			if(list.user_name.length == 0) {
				alert('이름을 입력해주세요')
				return false;
			} else if(!name.test(list.user_name)) {
				alert('이름을 올바로 입력해주세요')
				return false;
			} else if(list.user_number.length == 0) {
				alert('핸드폰 번호 입력해주세요')
				return false;
			} else if(!phone.test(list.user_number)) {
				alert('핸드폰 번호를 확인해 주세요')
				return false;
			} else if(list.user_birth.length == 0) {
				alert('생년월일 입력해주세요')
				return false;
			} else if(!birth.test(list.user_birth)){
				alert('생년월일 확인해주세요')
                return false;
			} else if(list.residence_type.length == 0) {
				alert('거주유형 선택해주세요')
				return false;
			} else if(list.house_type.length == 0) {
				alert('세대타입 선택해주세요')
				return false;
			} else if(list.move_date.length == 0) {
				alert('입주일 선택해주세요') 
				return false;
			} else if(list.prefer_floor.length == 0) {
				alert('선호층 선택해주세요') 
				return false;
			}
            console.log("저장")

			axios.post('https://duckyoung2016.com:8080/apply', JSON.stringify(list), {headers: {"Content-Type": `application/json`}})
            // axios.post('http:192.168.0.63:8080/apply', JSON.stringify(list), {headers: {"Content-Type": `application/json`}})
			.then(response => {
				if(response.data.statusCode == 200) {
					alert("신청이 완료되었습니다.")
                    this.$router.go();
				}else if(response.data.statusCode == 105){
                    console.log("신청기간이 아닙니다")
                    this.ModalOpen = !this.ModalOpen;
                } else if(response.data.statusCode == 102) {
                    alert("중복 신청입니다.\n휴대폰번호를 확인해주세요.\n\n지속적인 문제시 고객센터로 연락바랍니다.")   
                } else {	
					alert("신청에 실패했습니다.\n입력 내용을 확인해주세요.\n\n지속적인 문제시 고객센터로 연락바랍니다.");
				}
			}).catch(err => {
				console.log(err);
			})
        },
        // 특별공급
        send2(list){
			// 유효성검사 
			var name = /^[가-힣]{2,4}|[a-zA-Z]{2,10}\s[a-zA-Z]{2,10}$/; //이름
			var birth = /^(19[0-9][0-9]|20\d{2})-(0[0-9]|1[0-2])-(0[1-9]|[1-2][0-9]|3[0-1])$/ // 생년월일
			var phone = /^01([0|1|6|7|8|9])-?([0-9]{3,4})-?([0-9]{4})$/; //핸드폰 번호 
			var income = /^[0-9]*$/ // 월소득
			var property = /^[0-9]*$/ // 재산

            
			console.log(list)			

			if(list.user_name.length == 0) {
				alert('이름을 입력해주세요')
				return false;

			} else if(!name.test(list.user_name)) {
				alert('이름을 올바로 입력해주세요')
				return false;
			} else if(list.user_number.length == 0) {
				alert('핸드폰 번호 입력해주세요')
				return false;
			} else if(!phone.test(list.user_number)) {
				alert('핸드폰 번호를 확인해 주세요')
				return false;
			} else if(list.user_birth.length == 0) {
				alert('생년월일 입력해주세요')
				return false;
			} else if(!birth.test(list.user_birth)){
				alert('생년월일 확인해주세요')
                return false;
			} else if(list.address == 0) {
				alert('주소 입력해주세요')
				return false;
			} else if(list.income == 0) {
				alert('월 소득 입력해주세요')
				return false;
			} else if(!income.test(list.income)){
				alert('월 소득 확인해주세요')
			} else if(list.property == 0) {
				alert('재산 입력해주세요')
				return false;
			} else if(!property.test(list.property)){
				alert('재산 확인해주세요')
			} else if(list.residence_type.length == 0) {
				alert('거주유형 선택해주세요')
				return false;
			} else if(list.house_type.length == 0) {
				alert('세대타입 선택해주세요')
                return false;
			} else if(list.move_date.length == 0) {
				alert('입주일 선택해주세요') 
				return false;
			} else if(list.prefer_floor.length == 0) {
				alert('선호층 선택해주세요') 
				return false;
			}

            // if(list.house_type == '17A') {
			// 	list.house_type = '17AS'
			// } else if(list.house_type == '33A') {
			// 	list.house_type = '33AS'
			// } else if(list.house_type == '33B') {
			// 	list.house_type = '33BS' 
			// }


			axios.post('https://duckyoung2016.com:8080/apply', JSON.stringify(list), {headers: {"Content-Type": `application/json`}})
			.then(response => {
				if(response.data.statusCode == 200) {
					alert("신청이 완료되었습니다.")
                    this.$router.go();
				}else if(response.data.statusCode == 105){
                    console.log("신청기간이 아닙니다")
                    this.ModalOpen = !this.ModalOpen;
                } else if(response.data.statusCode == 102) {
                    alert("중복 신청입니다.\n휴대폰번호를 확인해주세요.\n\n지속적인 문제시 고객센터로 연락바랍니다.")   
                } else {	
					alert("신청에 실패했습니다.\n입력 내용을 확인해주세요.\n\n지속적인 문제시 고객센터로 연락바랍니다.");
				}
			}).catch(err => {
				console.log(err);
			})
        },
        // 당첨자
        WinnerBtn(list){
          // 유효성검사
			var name = /^[가-힣]{2,4}|[a-zA-Z]{2,10}\s[a-zA-Z]{2,10}$/; //이름
			var birth = /^(19[0-9][0-9]|20\d{2})-(0[0-9]|1[0-2])-(0[1-9]|[1-2][0-9]|3[0-1])$/ // 생년월일
			var phone = /^01([0|1|6|7|8|9])-?([0-9]{3,4})-?([0-9]{4})$/; //핸드폰 번호 


            console.log(list)

			if(list.user_name.length == 0) {
				alert('이름을 입력해주세요')

				return false;

			} else if(!name.test(list.user_name)) {
				alert('이름을 올바로 입력해주세요')

				return false;
			} 
			if(list.user_birth.length == 0) {
				alert('생년월일 입력해주세요')

				return false;

			} else if(!birth.test(list.user_birth)){
				alert('생년월일 확인해주세요')
			}
			if(list.user_number.length == 0){
				alert('핸드폰 번호 입력해주세요')

				return false;

			} else if(!phone.test(list.user_number)){
				alert('핸드폰 번호를 확인해 주세요')

				return false;
			}
            console.log("조회") 

			
            axios.get('https://duckyoung2016.com:8080/winner',{   
            // axios.get('http://192.168.0.63:8080/winner',{
                params: {
                    user_name : list.user_name,
                    user_number : list.user_number,
                    user_birth : list.user_birth
                }
            }).then(response => {
                console.log(response)
				if(response.data.statusCode == 200) {
                    this.isResult = true;
                    this.isResultModal = true;
                    // this.$router.push('/Dangcheom')
				}else if (response.data.statusCode == 103){	
                    this.isResult = false;
                    this.isResultModal = true;
                    // console.log(this.isResult);
                    // this.$router.push('/Failure')
                }else if(response.data.statusCode === 105){
                    console.log("조회기간이 아닙니다")
                    this.DangcheomModal = !this.DangcheomModal;
                }else {
                    alert("실패")
                }
			}).catch(err => {
				console.log(err);
			})
        },
        changeType(type) {
            this.$parent.contract_type = type;
        }
	}
}
</script>

<style lang="scss">
.Normal {position: relative; width: 100%;
    .inner{
        .sub_visual.sub1bg{
            .in{width:100%; text-align: center; padding-top: 70px;} 
        }
    }
    .sub_content{
        .main_title{position: relative; text-align: center;
            h2{margin-bottom: 60px; color: black; font-size: 38px; letter-spacing: -1px; font-weight: 500;} 
            h2:after{content: ''; display: block; margin: 15px auto 0; width: 35px; height: 3px; background: black;}
        }
        .main input[type=radio] { display: none; }
        #tab-1:checked ~ .tab label:nth-child(1),
        #tab-2:checked ~ .tab label:nth-child(2), 
        #tab-3:checked ~ .tab label:nth-child(3) {box-shadow: inset 0 -3px 0 #153E06; color: #153E06; font: normal normal bold 22px/33px Noto Sans KR; opacity: 1;}
        .content > div { display: none;}

        #tab-1:checked ~ .content div:nth-child(1),
        #tab-2:checked ~ .content div:nth-child(2),
        #tab-3:checked ~ .content div:nth-child(3) {display: block;}
        .main { margin: 0 auto; max-width: 1330px;}
        .tab { overflow: hidden; border-bottom: 1px solid #E0E0E0; opacity: 1; text-align: center; width: 1902px; margin-left: -292px;}
        .tab label {width: 206px; font: normal normal normal 22px/33px Noto Sans KR; letter-spacing: 0px; color: #626262; opacity: 1; cursor: pointer;text-align: center;text-align: center; padding: 15px 0; text-transform: uppercase; user-select: none; -webkit-user-select: none;}
        .tab label:visited{color: #153E06;}
        .content {min-height: 1000px;}
        .content > div{ line-height: 1.5; font-size: 17px;}
        

        .titlebox{
            .title{
                h2{font: normal normal 30px/36px S-Core Dream; letter-spacing: 0px; color: #333333; opacity: 1; margin-top: 55px; margin-bottom: 40px;}
            }
            .textbox{width: 1902px; background: #F5F5F5 0% 0% no-repeat padding-box; height: 460px; margin-left:-291px;
                .textbox_txt{padding-top: 43px; padding-left:293px;
                    // .text{text-align: left; font: normal normal 500 28px/41px Noto Sans KR; letter-spacing: 0px; color: #333333; opacity: 1;}
                    .text1{width: 20%; box-sizing: border-box; height: 155px;  border-right: 2px solid #153E06;
                        span{display: block; font: normal normal bold 20px/29px Noto Sans KR; letter-spacing: 0px; color: #153E06; opacity: 1;}
                    }
                    .text2{width: 75%; box-sizing: border-box; padding-left: 30px; margin-top: 10px; height: 165px; margin-bottom: 30px;
                        span{margin-top:-15px;text-align: left; display: block; font: normal normal 18px/18px Noto Sans KR; letter-spacing: 0px; color: #626262; margin-bottom: 8px; line-height: 2; opacity: 1;}
                        .small{font: normal normal normal 18px/25px Noto Sans KR; letter-spacing: 0px; color: #153E06; text-align: left; margin-left: 20px;}
                    }
                    p{float: left;}
                    .text5{width: 100%; margin-bottom: 8%; text-align: left; font: normal normal normal 16px/25px Noto Sans KR; letter-spacing: -0.32px; color: #626262; opacity: 1;}
                    .text6{width: 100%; margin-top:20px; margin-bottom: 8%; text-align: left;  font: normal normal normal 16px/25px Noto Sans KR; letter-spacing: -0.32px; color: #626262; opacity: 1;
                        // span{padding-left:1%; text-align: left; font: normal normal 300 14px/20px Noto Sans KR; letter-spacing: 0px; color: #727272;} 
                    }
                }   
                // .line{border: 1px solid #D5D5D5; opacity: 1; margin-bottom: 10px;}
            }
            // 일반공급 특별공급
            .application{ 
                // input[type=radio] { display: block; margin-top:5px; margin-right: 5px; float: left;}
                .application_title{text-align: left; font: normal normal 500 28px/41px Noto Sans KR; letter-spacing: 0px; color: #333333; opacity: 1; margin-bottom: 15px;}
                table{width: 100%; margin-bottom: 10px; border-collapse: collapse; border-spacing: 0; 
                    .content_title{text-align: center; background: #F5F5F5 0% 0% no-repeat padding-box; opacity: 1; width: 208px; height: 50px; font: normal normal bold 20px/29px Noto Sans KR; letter-spacing: 1.92px; color: #333333;}
                    tr{border-bottom: 1px solid #E0E0E0; }
                    td{font-size: 15px; padding: 10px; overflow: hidden; word-break:normal; font-weight: 400;}
                }
                .txt{text-align: left; font: normal normal normal 16px/25px Noto Sans KR; letter-spacing: -0.24px; color: #626262; opacity: 1;}
                img{opacity: 1; display: block; margin: 0px auto; margin-top: 5%; cursor: pointer;}
                .radio{display: inline-block;}
                label{width: 115px; text-align: left; font: normal normal 300 20px/29px Noto Sans KR; letter-spacing: 1.5px; color: #626262; opacity: 1; margin-right: 50px; cursor: pointer;}
                .box{padding-left: 40px;
                    span{font: normal normal normal 14px/25px Noto Sans KR; letter-spacing: -0.24px; color: #727272; margin-top: 10px; vertical-align: middle;}
                    span.description {font: normal normal 300 20px/25px Noto Sans KR; color: #727272;}
                }
                
            }
            // 당첨자조회
            .Winner_title{
                h2{font: normal normal bold 36px/43px S-Core Dream; letter-spacing: 2.16px; color: #333333; opacity: 1; text-align: center; margin-top: 95px; margin-bottom: 58px;}
            }
            .winner{
                table{width: 100%; margin-bottom: 50px; border-collapse: collapse; border-spacing: 0; 
                    .content_title{font: normal normal bold 20px/29px Noto Sans KR; text-align: center; letter-spacing: 1.92px; color: #333333; opacity: 1; background: #F5F5F5 0% 0% no-repeat padding-box; opacity: 1; width: 208px; padding-left: 10px;}
                    tr{border: 1px solid #E0E0E0; }
                    td{padding: 10px; overflow: hidden; word-break:normal; padding-left:40px;}
                    span.description {font: normal normal 300 20px/25px Noto Sans KR; color: #727272;}
                }
                img{opacity: 1; display: block; margin: 0px auto; margin-top: 5%; cursor: pointer;}
            }
        }
        button{margin-top: 12px; background: #153E06 0% 0% no-repeat padding-box; border-radius: 12px; opacity: 1; height: 56px; width: 225px; border: 0; display: block; margin: 0 auto;
            p{text-align: center; font: normal normal bold 24px/36px Noto Sans KR; letter-spacing: 0px; color: #FFFFFF; margin-bottom: 0px;} 
        }
        .contactSection{margin-bottom: 15%;}
    }
    // p.sub-title {font: normal normal bold 14px/18px S-Core Dream; color:#2F4B98; margin-top: 84px; margin-bottom: 9px;}
}
</style>